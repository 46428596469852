const CryptoJS = require("crypto-js");
const SecureStorage = require("secure-web-storage");
const SECRET_KEY = "secureMyData";

export var secureStorage = new SecureStorage(localStorage, {
  hash: function hash(key) {
    key = CryptoJS.SHA256(key, SECRET_KEY);
    return key.toString();
  },
  encrypt: function encrypt(data) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);
    data = data.toString();
    return data;
  },
  decrypt: function decrypt(data) {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY);
    data = data.toString(CryptoJS.enc.Utf8);
    return data;
  },
});

const initialState = {
  token: secureStorage.getItem("token") ? secureStorage.getItem("token") : null,
  userData: secureStorage.getItem("userData")
    ? secureStorage.getItem("userData")
    : {},
  userType: secureStorage.getItem("userData")
    ? secureStorage.getItem("userData")?.userType
    : null,
};
const authReducer = (state = initialState, action) => {
  const { type, user, token } = action;
  switch (type) {
    case "USER":
      console.log("user", user);
      secureStorage.setItem("userData", user);
      secureStorage.setItem("token", token);
      return {
        ...state,
        isLoading: false,
        userData: user,
        token: token,
        userType: user.userType,
      };
    case "USERUPDATE":
      secureStorage.setItem("userData", user);
      return {
        ...state,
        isLoading: false,
        userData: user,
      };

    case "LOGOUT":
      secureStorage.removeItem("userData");
      secureStorage.removeItem("token");
      return {
        ...state,
        userData: {},
        token: "",
        userType: "",
      };
    default:
      return state;
  }
};

export const currentlocationReducer = (
  state = initialState,
  location = [],
  action
) => {
  const { type } = action;
  switch (type) {
    case CURRENTLOCATION.REQUEST_CURRENTLOCATION:
      return { ...state, loading: true, isError: false };
    case CURRENTLOCATION.SUCCESS_CURRENTLOCATION:
      return {
        ...state,
        loading: false,
        isError: false,
        location: action.payload,
      };
    case CURRENTLOCATION.FAILED_CURRENTLOCATION:
      return {
        ...state,
        loading: false,
        isError: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
