import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import { uploadFile } from "src/utils/fileupload";
import { httpPost } from "src/Redux/Actions/AuthAction";

// material
import { Stack, TextField, Typography, Container, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import CustomizedSnackbars from "src/components/snackBar";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: "50%",
  flexDirection: "column",
  padding: theme.spacing(5, 0),
}));

export default function Product() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [disablep, setDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [msgType, setMsgType] = useState("");
  const [msg, setMsg] = useState("");
  const LoginSchema = Yup.object().shape({
    // image: Yup.string().required('Image is required'),
    name: Yup.string().required("Name is required"),
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setValues,
    handleSubmit,
    getFieldProps,
    resetForm,
    setSubmitting,
  } = useFormik({
    initialValues: {
      _id: undefined,
      name: "",
      image: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      setLoading(true);
      let data = values;
      let url = id === "create" ? "products/create" : "products/update";
      let ndata = {};
      ndata["_id"] = data?.["_id"];
      data = ndata;
      console.log("sds", values);
      let res = await httpPost(url, values);
      if (res?.success) {
        resetForm();
        setLoading(false);
        setMsgType("success");
        setMsg("saved successfully");
        navigate(-1);
      } else {
        setMsgType("error");
        setMsgType(res.message);
      }
    },
  });

  const uploadImage = async (e) => {
    let files = [...e.target.files];
    let formData = new FormData();
    formData.append("image", files[0]);
    let res = await httpPost("user/fileupload", formData, {
      headers: {
        "Content-Type": "application/json",
        Accept: "multipart/form-data",
      },
    });
    if (res?.success) {
      setValues({ ...values, ["image"]: res?.data });
    }
  };

  useEffect(() => {
    if (state?._id) {
      setValues(state);
    }
  }, [state?._id]);

  return (
    <Container>
      <Typography sx={{ fontWeight: "800", fontSize: 30 }}>
        {id === "create" ? "Create product" : `Edit Product : ${state?.name}`}
      </Typography>
      <ContentStyle>
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack justifyContent="space-between" sx={{ my: 2 }}>
            {/* <Typography sx={{ fontWeight: "800" }}>
                            Basic Details
                        </Typography> */}
            <Box width={"100%"} sx={{ display: "flex", my: 2 }}>
              <TextField
                fullWidth
                style={{ margin: "0px 10px 0px 0px" }}
                type="text"
                label="Name"
                {...getFieldProps("name")}
                disabled={disablep}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Box>
            <Box width={"100%"} sx={{ display: "flex", my: 2 }}>
              <TextField
                fullWidth
                inputProps={{ multiple: true, accept: ".jpg,.jpeg,.png,.wbep" }}
                InputLabelProps={{ shrink: true }}
                accept=".jpg,.jpeg,.png,.webp"
                style={{ margin: "0px 10px 0px 0px" }}
                type="file"
                label="Upload Image"
                disabled={disablep}
                onChange={uploadImage}
                onClick={(event) => {
                  event.currentTarget.value = null;
                }}
                name="image"
                error={Boolean(touched.image && errors.image)}
                helperText={touched.image && errors.image}
              />
              {values?.image ? (
                <img
                  style={{ marginTop: 2 }}
                  src={values?.image}
                  height={50}
                  width={50}
                />
              ) : (
                ""
              )}
            </Box>
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            // onClick={submit}
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            {id === "create" ? "Add Product" : "Update Product"}
          </LoadingButton>
        </form>
      </ContentStyle>
      <CustomizedSnackbars infoType={msgType} message={msg} />
    </Container>
  );
}
