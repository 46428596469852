import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, Navigate, useLocation } from "react-router-dom";
import _ from "lodash";
import { AppBugReports, AppWeeklySales } from "../components/_dashboard/app";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  Grid,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../components/_dashboard/user";
//
import USERLIST from "../_mocks_/user";
import { httpPost } from "src/Redux/Actions/AuthAction";
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "_id", label: "Id", alignRight: false },
  { id: "company", label: "Company", alignRight: false },
  { id: "Product", label: "Product", alignRight: false },
  { id: "QR", label: "QR", alignRight: false },
  { id: "PIN", label: "PIN", alignRight: false },
  { id: "Assigned", label: "Assigned", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function Company() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [iqyData, setIqyData] = useState([]);
  const { state } = useLocation();
  const { userType } = useSelector((state) => state.authReducer);
  const group = _.groupBy(iqyData, "product.name");
  console.log("group", group);
  if (userType !== "ADMIN") {
    return <Navigate to="/" />;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = iqyData?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const getData = async () => {
    let res = await httpPost("qr/getQRCompany", { company: state?._id });
    if (!res?.err) {
      console.log("res", res);
      setIqyData(res?.data);
    }
  };

  useEffect(() => {
    getData();
  }, [state?._id]);

  const deleteApplication = async (row, idx) => {
    let res = await httpPost("company/getAll", { id: row._id });
    const newList = iqyData.filter((item) => item._id !== row._id);
    setIqyData(newList);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - iqyData.length) : 0;
  const filteredUsers = applySortFilter(
    iqyData,
    getComparator(order, orderBy),
    filterName
  );
  const isUserNotFound = filteredUsers.length === 0;
  return (
    <Page title="User | console">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            QR List - {state?.name}
          </Typography>
          {/* <Button
                        variant="contained"
                        component={RouterLink}
                        to="/company/create"
                        startIcon={<Icon icon={plusFill} />}
                    >
                        New Company
                    </Button> */}
        </Stack>
        <Grid container spacing={3} mb={3}>
          {Object.keys(group).map((res, idx) => (
            <Grid key={idx} item xs={12} sm={6} md={3}>
              <AppWeeklySales res={res} count={group[res]} />
            </Grid>
          ))}
        </Grid>
        <Card>
          <UserListToolbar
            label="Search application"
            numSelected={selected.length}
            csvDataArr={iqyData}
            fileName={state?.name}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={iqyData?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((row, idx) => {
                      const {
                        _id,
                        name,
                        product,
                        company,
                        reportUrl,
                        mobile,
                        password,
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          // role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left">{idx + 1}</TableCell>
                          <TableCell align="left">
                            {company?.name ? company?.name : ""}
                          </TableCell>
                          <TableCell align="left">
                            {product?.name ? product?.name : ""}
                          </TableCell>
                          <TableCell align="left">
                            <QRCode value={reportUrl} size={48} />
                          </TableCell>
                          <TableCell align="left">
                            {password ? password : ""}
                          </TableCell>
                          <TableCell align="left">
                            {mobile ? mobile : ""}
                          </TableCell>
                          {/* <TableCell align="right">
                                                        <UserMoreMenu link={'/company/edit'} data={row} onDelete={() => { deleteApplication(row, idx) }} />
                                                    </TableCell> */}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {/* {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )} */}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={iqyData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
