import { useState } from "react";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import DownloadFill from "@iconify/icons-eva/download-fill";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  Button,
  InputAdornment,
} from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function UserListToolbar({
  numSelected,
  filterName,
  onFilterName,
  label,
  csvDataArr,
  fileName,
  hideDownload,
  type,
}) {
  console.log("csvDataArr", csvDataArr);
  const [csvData, setCSVData] = useState([]);

  const handleClickCSV = () => {
    const csvRecords = [];
    if (type === "ORG") {
      csvDataArr?.map((report) => {
        delete report._id;
        csvRecords.push({
          Organization: report.name,
          email: report?.email,
          mobile: report?.mobile,
          address: report?.address,
        });
      });
    } else {
      csvDataArr?.map((report) => {
        csvRecords.push({
          Date: moment(report?.date).format("DD/MM/YYYY"),
          Organization: report?.company?.name,
          Product: report?.product?.name,
          QR: report?.reportUrl,
          PIN: report?.password,
          Assigned: report?.mobile,
          Status: report?.mobile ? true : false,
        });
      });
    }
    setCSVData(csvRecords);
  };

  console.log("csvRecords", csvData);

  console.log("csvData", csvData);
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder={`${label} ...`}
            startAdornment={
              <InputAdornment position="start">
                <Box
                  component={Icon}
                  icon={searchFill}
                  sx={{ color: "text.disabled" }}
                />
              </InputAdornment>
            }
          />
          {!hideDownload && (
            <Tooltip title="Download CSV">
              <Button
                // color="inherit"

                variant="contained"
                // variant="outlined"
                startIcon={<Icon icon={DownloadFill} />}
                onClick={() => handleClickCSV()}
              >
                <CSVLink
                  data={csvData}
                  filename={`${fileName}.csv`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Download CSV
                </CSVLink>
              </Button>
            </Tooltip>
          )}
        </>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : (
        ""
      )}
    </RootStyle>
  );
}
