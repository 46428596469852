import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import { uploadFile } from "src/utils/fileupload";
import { httpPost } from "src/Redux/Actions/AuthAction";

// material
import {
  Stack,
  TextField,
  Typography,
  Container,
  Box,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import CustomizedSnackbars from "src/components/snackBar";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: "50%",
  flexDirection: "column",
  padding: theme.spacing(5, 0),
}));

export default function Product() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [disablep, setDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [msgType, setMsgType] = useState("");
  const [msg, setMsg] = useState("");
  const [companyList, setCompanyData] = useState([]);
  const [productList, setProductData] = useState([]);
  const LoginSchema = Yup.object().shape({
    company: Yup.string().required("company is required"),
    product: Yup.string().required("product is required"),
    numb: Yup.string().required("number of QR is required"),
    password: Yup.string().max(4).min(4).required("password is required"),
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setValues,
    handleSubmit,
    getFieldProps,
    resetForm,
    setSubmitting,
  } = useFormik({
    initialValues: {
      numb: "",
      password: "",
      product: "",
      company: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      setLoading(true);
      let data = values;
      let url = "qr/create";
      let res = await httpPost(url, values);
      if (res?.success) {
        resetForm();
        setLoading(false);
        setMsgType("success");
        setMsg("saved successfully");
        // navigate(-1);
      } else {
        setMsgType("error");
        setMsgType(res.message);
      }
    },
  });

  const getDataProducts = async () => {
    let res = await httpPost("products/getAll", {});
    if (!res?.err) {
      console.log("res", res);
      setProductData(res?.data);
    }
  };

  const getDataCompany = async () => {
    let res = await httpPost("company/getAll", {});
    if (!res?.err) {
      console.log("res", res);
      setCompanyData(res?.data);
    }
  };

  useEffect(() => {
    if (state?._id) {
      setValues(state);
    }
  }, [state?._id]);

  useEffect(() => {
    getDataProducts();
    getDataCompany();
  }, []);

  const handleChange = (e) => {
    let isnum = e.target.value ? /^\d+$/.test(e.target.value) : true;
    console.log("ism", isnum);
    if (e.target.name === "password" && isnum && e.target.value?.length <= 4) {
      setValues({ ...values, ["password"]: e.target.value });
    }
  };

  return (
    <Container>
      <Typography sx={{ fontWeight: "800", fontSize: 30 }}>
        Create QR
      </Typography>
      <ContentStyle>
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack justifyContent="space-between" sx={{ my: 2 }}>
            {/* <Typography sx={{ fontWeight: "800" }}>
                            Basic Details
                        </Typography> */}
            <Box width={"100%"} sx={{ display: "flex", my: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Product
                </InputLabel>
                <Select
                  {...getFieldProps("product")}
                  error={Boolean(touched.product && errors.product)}
                  helperText={touched.product && errors.product}
                >
                  {productList?.map((res, idx) => (
                    <MenuItem key={idx} value={res?._id}>
                      {res?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box width={"100%"} sx={{ display: "flex", my: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Organization
                </InputLabel>
                <Select
                  {...getFieldProps("company")}
                  error={Boolean(touched.company && errors.company)}
                  helperText={touched.company && errors.company}
                >
                  {companyList?.map((res, idx) => (
                    <MenuItem key={idx} value={res?._id}>
                      {res?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box width={"100%"} sx={{ display: "flex", my: 2 }}>
              <TextField
                fullWidth
                style={{ margin: "0px 10px 0px 0px" }}
                type="number"
                label="Number of QR"
                {...getFieldProps("numb")}
                disabled={disablep}
                error={Boolean(touched.numb && errors.numb)}
                helperText={touched.numb && errors.numb}
              />
            </Box>
            <Box width={"100%"} sx={{ display: "flex", my: 2 }}>
              <TextField
                fullWidth
                style={{ margin: "0px 10px 0px 0px" }}
                type="text"
                value={values?.password}
                label="enter 4 digit password"
                name="password"
                onChange={handleChange}
                disabled={disablep}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </Box>
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            // onClick={submit}
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            {"Add QR"}
          </LoadingButton>
        </form>
      </ContentStyle>
      <CustomizedSnackbars infoType={msgType} message={"Processed"} />
    </Container>
  );
}
