import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import FileSaver from "file-saver";
import axios from "axios";
// material
import {
  Link,
  Stack,
  Checkbox,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { httpPost } from "src/Redux/Actions/AuthAction";
import { useDispatch } from "react-redux";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [msg, setMsg] = useState("");
  const dispatch = useDispatch();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  useEffect(async () => {
    // let res = await httpPost();
    downloadImage();
  }, []);

  async function downloadImage() {
    // Make a request to the API to get the binary image data
    await axios
      .post(
        "http://localhost:3001/api/lockScreen/getlockWithQr",
        {
          file: "https://okappimage.s3.amazonaws.com/1680115488396.jpg",
          url: "https://report.okwale.com/64224e4ffb4f5c210c867cbd",
        },
        { responseType: "arraybuffer" }
      )
      .then((response) => {
        // Create a Blob from the binary image data
        const blob = new Blob([response.data], { type: "image/jpeg" });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Create an <a> element with the download attribute and the Blob URL
        const a = document.createElement("a");
        a.href = url;
        a.download = "image.png";

        // Simulate a click on the <a> element to trigger the download
        a.click();

        // Clean up the Blob URL
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  }

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    setSubmitting,
    isSubmitting,
    getFieldProps,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      setMsg("");
      let res = await httpPost("user/login", values);
      console.log("res", res);
      setSubmitting(false);
      if (res?.err) {
        setMsg(res.msg);
      } else {
        dispatch({ type: "USER", token: res.token, user: res.user });
      }
    },
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <TextField
          fullWidth
          autoComplete="username"
          type="email"
          label="Email address"
          {...getFieldProps("email")}
          error={Boolean(touched.email && errors.email)}
          helpertext={touched.email && errors.email}
        />

        <TextField
          fullWidth
          autoComplete="current-password"
          type={showPassword ? "text" : "password"}
          label="Password"
          {...getFieldProps("password")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={Boolean(touched.password && errors.password)}
          helpertext={touched.password && errors.password}
        />
      </Stack>
      <Typography
        style={{ marginLeft: 8 }}
        variant="body2"
        color="red"
        component="p"
      >
        {msg}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <FormControlLabel
          control={
            <Checkbox
              {...getFieldProps("remember")}
              checked={values.remember}
            />
          }
          label="Remember me"
        />
        <Link component={RouterLink} variant="subtitle2" to="#">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>
    </form>
  );
}
