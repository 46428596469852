import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
// components
import Logo from "../components/Logo";
//
import { MHidden } from "../components/@material-extend";
import { useSelector } from "react-redux";
import { useEffect } from "react";

// ----------------------------------------------------------------------

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

// ----------------------------------------------------------------------

AuthLayout.propTypes = {
  children: PropTypes.node,
};

export default function AuthLayout({ children }) {
  const { token, userType } = useSelector((state) => state.authReducer);
  const navigate = useNavigate();
  useEffect(() => {
    if (token && userType === "ADMIN") {
      navigate("/dashboard", { replace: true });
    }
  }, [token]);
  return (
    <HeaderStyle>
      <RouterLink to="/">
        <Logo />
      </RouterLink>

      <MHidden width="smDown">
        <Typography
          variant="body2"
          sx={{
            mt: { md: -2 },
          }}
        >
          {children}
        </Typography>
      </MHidden>
    </HeaderStyle>
  );
}
