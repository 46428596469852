import AWS from 'aws-sdk';

const S3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
});



export const fileupload = {
}

// aws s3 function to upload file
export const uploadFile = async (file, cb) => {
    const fileName = file?.name;
    const fileType = file?.type;
    const filePath = `${fileName}`;
    console.log("file",file)
    const fileContent = await file?.arrayBuffer();
    const fileBuffer = Buffer.from(fileContent);

    const params = {
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
        Key: new Date().valueOf() + filePath,
        Body: fileBuffer,
        ContentType: fileType,
        // ACL: 'public-read',
    };
    const result = await S3.upload(params).promise();;
    // console.log("resussdsd",result)
    if (cb) { cb(result) };
    return result;
}