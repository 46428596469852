import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
import Snackbar from '../components/snackBar';
// material
import { Stack, TextField, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { uploadFile } from 'src/utils/fileupload';
import { httpPost } from 'src/Redux/Actions/AuthAction';
import { useDispatch, useSelector } from 'react-redux';

// ----------------------------------------------------------------------

export default function ProfileUpdate() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userData } = useSelector(state => state.authReducer)
    console.log("userData", userData)
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState('false');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const RegisterSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').max(30, 'Too Long!').required('Company name required'),
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required'),
        image: Yup.string().required('Image is required'),
        phone: Yup.string().matches(/^[0-9]+$/, "Phone number mus be digits only").min(10, 'Must be exactly 10 digits').max(10, 'Must be exactly 10 digits').required('Phone number is required'),
        addressLine1: Yup.string().required('address is required'),
        area: Yup.string().required('area is required'),
        pincode: Yup.string().matches(/^[0-9]+$/, "pincode mus be digits only").min(6, 'Must be exactly 6 digits').max(6, 'Must be exactly 6 digits').required('Pincode is required'),
        city: Yup.string().required('city is required'),
    });

    const { errors, touched, values, handleSubmit, handleChange, setSubmitting, isSubmitting, getFieldProps, setValues } = useFormik({
        initialValues: {
            name: "",
            email: "",
            password: "",
            image: "",
            phone: "",
            addressLine1: "",
            addressLine2: "",
            area: "",
            pincode: "",
            city: "",
        },
        validationSchema: RegisterSchema,
        onSubmit: async (e) => {
            let res = await httpPost('user/update', values);
            setSubmitting(false);
            setMessage(res.msg);
            dispatch({ type: "USERUPDATE", user: values })
            setMessageType(res.err ? 'error' : 'success');
            setMessage(res?.err ? "Error in update " : "Update successfully")
            setTimeout(() => {
                setMessageType("");
                setMessage("")
            }, 6000);
        }
    });
    const uploadImage = async (e) => {
        handleChange(e);
        let result = await uploadFile(e.target.files[0]);
        setValues({ "image": result.Location });
    }

    useEffect(() => {
        setValues(userData);
    }, [])





    return (
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Typography sx={{ fontWeight: "600", fontSize: 22, marginTop: -2 }}>
                Profile
            </Typography>
            <Stack spacing={3} sx={{ marginTop: 5 }}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField
                        fullWidth
                        label="Company Name"
                        {...getFieldProps('name')}
                        disabled
                        error={Boolean(touched.name && errors.name)}
                        helpertext={touched.name && errors.name}
                    />
                </Stack>

                <TextField
                    fullWidth
                    autoComplete="username"
                    type="email"
                    label="Email address"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helpertext={touched.email && errors.email}
                />
                <TextField
                    fullWidth
                    type="file"
                    label="Company Logo"
                    onChange={uploadImage}
                    name={'image'}
                    error={Boolean(touched.image && errors.image)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    helpertext={touched.image && errors.image}
                />

                <TextField
                    fullWidth
                    type="text"
                    label="Phone Number"
                    disabled
                    onChange={handleChange}
                    {...getFieldProps('phone')}
                    error={Boolean(touched.phone && errors.phone)}
                    helpertext={touched.phone && errors.phone}
                    inputProps={{ maxLength: 10 }}
                />
                <TextField
                    fullWidth
                    type="text"
                    label="Address Line 1"
                    {...getFieldProps('addressLine1')}
                    error={Boolean(touched.addressLine1 && errors.addressLine1)}
                    helpertext={touched.addressLine1 && errors.addressLine1}
                />
                <TextField
                    fullWidth
                    type="text"
                    label="Address Line 2"
                    {...getFieldProps('addressLine2')}
                    error={Boolean(touched.addressLine2 && errors.addressLine2)}
                    helpertext={touched.addressLine2 && errors.addressLine2}
                />
                <TextField
                    fullWidth
                    type="text"
                    label="Area"
                    {...getFieldProps('area')}
                    error={Boolean(touched.area && errors.area)}
                    helpertext={touched.area && errors.area}
                />
                <TextField
                    fullWidth
                    type="text"
                    label="Pincode"
                    {...getFieldProps('pincode')}
                    error={Boolean(touched.pincode && errors.pincode)}
                    helpertext={touched.pincode && errors.pincode}
                    inputProps={{ maxLength: 6 }}
                />
                <TextField
                    fullWidth
                    type="text"
                    label="City"
                    {...getFieldProps('city')}
                    error={Boolean(touched.city && errors.city)}
                    helpertext={touched.city && errors.city}
                />
                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    Update Profile
                </LoadingButton>
            </Stack>
            <Snackbar infoType={messageType} message={message} />
        </form>
    );
}
