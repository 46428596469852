import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import { uploadFile } from "src/utils/fileupload";
import { httpPost } from "src/Redux/Actions/AuthAction";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { DatePicker,LocalizationProvider } from '@mui/lab';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

// material
import { Stack, TextField, Typography, Container, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import CustomizedSnackbars from "src/components/snackBar";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: "50%",
  flexDirection: "column",
  padding: theme.spacing(5, 0),
}));

export default function AddUsers() {
  const { id } = useParams();
  const { token } = useSelector((state) => state.authReducer);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [disablep, setDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [msgType, setMsgType] = useState("");
  const [msg, setMsg] = useState("");
  const LoginSchema = Yup.object().shape({
    // image: Yup.string().required('Image is required'),
    name: Yup.string().required("Name is required"),
    email: Yup.string().email().required("email is required"),
    address: Yup.string().required("Price is required"),
    image: Yup.string().required("image is required"),
    mobile: Yup.string().required("Phone number is required!").min(10),
  });
  const {
    errors,
    touched,
    values,
    isSubmitting,
    setValues,
    handleSubmit,
    getFieldProps,
    resetForm,
    setSubmitting,
  } = useFormik({
    initialValues: {
      _id: undefined,
      name: "",
      email: "",
      image: "",
      address: "",
      mobile: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      setLoading(true);
      let data = values;
      let url = id === "create" ? "company/create" : "company/update";
      let ndata = {};
      ndata["_id"] = data?.["_id"];
      data = ndata;
      let res = await httpPost(url, values);
      if (res?.success) {
        resetForm();
        setLoading(false);
        setMsgType("success");
        setMsg("saved successfully");
        navigate(-1);
      } else {
        setMsgType("error");
        setMsgType(res.message);
      }
    },
  });

  const uploadImage = async (e) => {
    let files = [...e.target.files];
    let formData = new FormData();
    formData.append("image", files[0]);
    let res = await httpPost("user/fileupload", formData, {
      headers: {
        "Content-Type": "application/json",
        Accept: "multipart/form-data",
      },
    });
    if (res?.success) {
      setValues({ ...values, ["image"]: res?.data });
    }
  };

  const setDate = (name, value) => {
    console.log(name, value);
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    if (state?._id) {
      setValues(state);
    }
  }, [state?._id]);

  const handleChange = (e) => {
    let isnum = e.target.value ? /^\d+$/.test(e.target.value) : true;
    console.log("ism", isnum);
    if (e.target.name === "mobile" && isnum && e.target.value?.length <= 10) {
      setValues({ ...values, ["mobile"]: e.target.value });
    }
  };

  return (
    <Container>
      <Typography sx={{ fontWeight: "800", fontSize: 30 }}>
        {id === "create" ? "Add Company" : `Edit Company : ${state?.name}`}
      </Typography>
      <ContentStyle>
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack justifyContent="space-between" sx={{ my: 2 }}>
            <Box width={"100%"} sx={{ display: "flex", my: 2 }}>
              <TextField
                fullWidth
                style={{ margin: "0px 10px 0px 0px" }}
                type="text"
                label="Name"
                {...getFieldProps("name")}
                disabled={disablep}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Box>
            <Box width={"100%"} sx={{ display: "flex", my: 2 }}>
              <TextField
                fullWidth
                style={{ margin: "0px 10px 0px 0px" }}
                type="email"
                label="email"
                {...getFieldProps("email")}
                disabled={disablep}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Box>
            <Box width={"100%"} sx={{ display: "flex", my: 2 }}>
              <TextField
                fullWidth
                style={{ margin: "0px 10px 0px 0px" }}
                type="text"
                label="mobile"
                name="mobile"
                value={values?.mobile}
                onChange={handleChange}
                disabled={disablep}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
              />
            </Box>
            <Box width={"100%"} sx={{ display: "flex", my: 2 }}>
              <TextField
                fullWidth
                style={{ margin: "0px 10px 0px 0px" }}
                type="text"
                label="Address"
                multiline
                rows={4}
                {...getFieldProps("address")}
                disabled={disablep}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />
            </Box>
            <Box width={"100%"} sx={{ display: "flex", my: 2 }}>
              <TextField
                fullWidth
                inputProps={{ multiple: true, accept: ".jpg,.jpeg,.png,.wbep" }}
                InputLabelProps={{ shrink: true }}
                accept=".jpg,.jpeg,.png,.webp"
                style={{ margin: "0px 10px 0px 0px" }}
                type="file"
                label="Upload Image"
                disabled={disablep}
                onChange={uploadImage}
                onClick={(event) => {
                  event.currentTarget.value = null;
                }}
                name="image"
                error={Boolean(touched.image && errors.image)}
                helperText={touched.image && errors.image}
              />
              {values?.image ? (
                <img
                  style={{ marginTop: 2 }}
                  src={values?.image}
                  height={50}
                  width={50}
                />
              ) : (
                ""
              )}
            </Box>
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            // onClick={submit}
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            {id === "create" ? "Submit" : "Update"}
          </LoadingButton>
        </form>
      </ContentStyle>
      <CustomizedSnackbars infoType={msgType} message={msg} />
    </Container>
  );
}
