import axios from './interceptor';
const APIURL = "ADMIN@gmail.com";
// export const httPost = (data) => async (dispatch) => {
//     request();
//     const res = await registrationService.Registration(data);
//     try {
//         success(res);
//         return Promise.resolve(res.data);

//     } catch (err) {
//         failure(err)
//         return Promise.reject(err);
//     }

//     function request() {
//         dispatch({ type: REGISTER.REQUEST_REGISTRATION });
//     };

//     function success(res) {
//         dispatch({ type: REGISTER.SUCCESS_REGISTRATION, payload: res.data, });
//     };

//     function failure(err) {
//         dispatch({
//             type: REGISTER.FAILED_REGISTRATION,
//             payload: err
//         });
//     };
// };

export const httpPost = async (url, data) => {
   return await axios.post(`${url}`, data).then(res => {
        return res.data
    }).catch(err => {
        let status = err?.response?.status;
        if (status === 500 || status === 400 || status === 401) {
            return err?.response?.data;
        }
    })
}


