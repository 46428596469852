import { Icon } from '@iconify/react';
import androidFilled from '@iconify/icons-ant-design/key';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  // marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

const TOTAL = 714000;

export default function AppWeeklySales({ res, count }) {
  const cnt = count.length;
  const used = count?.filter(x => x?.mobile)?.length;
  return (
    <RootStyle>
      <IconWrapperStyle>
        {count[0]?.product?.image ? <img src={count[0]?.product?.image} alt="" height={30} width={30} />  :
          <Icon icon={androidFilled} width={24} height={24} />}
      </IconWrapperStyle>
      <Typography variant="h3">{fShortenNumber(cnt)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {res}
      </Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Assigned: {used}
      </Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Un Assigned: {cnt - used}
      </Typography>
    </RootStyle>
  );
}
