import axios from "axios";
import store from "../../Redux/store/store";
const customAxios = axios.create({
  // baseURL: "http://localhost:3001/api/",
  baseURL:'https://console.okwale.com/api/',
  withCredentials: false,
});

function getToken() {
  let {
    authReducer: { token },
  } = store.getState();
  return token;
}

// Step-2: Create request, response & error handlers

const requestHandler = (request) => {
  request.headers.Authorization = getToken();
  return request;
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  if (error?.response?.status === 401) {
    // toast.error("Session Expired");
  } else {
    // toast.error(error.message);
  }
  return Promise.reject(error);
};

customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default customAxios;
