import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import applicationFill from "@iconify/icons-eva/archive-fill";
import giftFill from "@iconify/icons-eva/gift-fill";
import certificateFill from "@iconify/icons-eva/award-fill";
import ExamFill from "@iconify/icons-eva/file-text-fill";
import MsgFill from "@iconify/icons-eva/file-fill";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: "Dashboard",
    path: "/dashboard/",
    icon: getIcon(pieChart2Fill),
    isAccess: false,
  },
  {
    title: "Organization",
    path: "/company/list",
    icon: getIcon(applicationFill),
    isAccess: false,
  },
  {
    title: "Products",
    path: "/products/list",
    icon: getIcon(giftFill),
    isAccess: false,
  },
  {
    title: "QR CODE",
    path: "/qr/add",
    icon: getIcon(certificateFill),
    isAccess: false,
  },
];

export default sidebarConfig;
