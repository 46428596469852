// material
import { Box, Grid, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { httpPost } from "src/Redux/Actions/AuthAction";
// components
import Page from "../components/Page";
import { AppBugReports, AppWeeklySales } from "../components/_dashboard/app";
import User from "./users";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [allCount, setCounts] = useState({});
  const { userType } = useSelector((state) => state.authReducer);
  const navigate = useNavigate();
  if (userType !== "ADMIN") {
    navigate("/");
  }

  const getDataCompany = async () => {
    let res = await httpPost("qr/counts", {});
    if (!res?.err) {
      setCounts(res?.data);
    }
  };

  useEffect(() => {
    getDataCompany();
  }, []);

  return (
    <Page title="Dashboard | console">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Dashboard</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports name={"Organization"} count={allCount?.company} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports name={"Products"} count={allCount?.product} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports name={"QR"} count={allCount?.qr} />
          </Grid>
        </Grid>
        {/* <Grid sx={{ mt: 3 }}>
          <User hide={true} />
        </Grid> */}
      </Container>
    </Page>
  );
}
