import { Navigate, useNavigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Login";
// import Register from './pages/Register';
import DashboardApp from "./pages/DashboardApp";
// import Users from './pages/users';
// import UserInfo from './pages/emergency';
import Profile from "./pages/profile";
import AddUsers from "./pages/AddUsers";
import AddProducts from "./pages/AddProducts";
import Users from "./pages/users";
import Products from "./pages/products";
import QRAdd from "./pages/addqr";
import QRList from "./pages/qrList";
import NotFound from "./pages/Page404";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [{ path: "/dashboard", element: <DashboardApp /> }],
    },
    {
      path: "/profile",
      element: <DashboardLayout />,
      children: [{ element: <Profile /> }],
    },
    // {
    //   path: '/users',
    //   element: <DashboardLayout />,
    //   children: [
    //     { element: <Navigate to="/users/list" replace /> },
    //     { path: 'list', element: <Users /> },
    //     // { path: 'info', element: <UserInfo /> },
    //   ]
    // },
    {
      path: "/company",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/company/list" replace />, index: true },
        { path: "list", element: <Users /> },
        { path: ":id", element: <AddUsers /> },
      ],
    },
    {
      path: "/qr",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/qr/add" replace /> },
        { path: "add", element: <QRAdd /> },
        { path: "list", element: <QRList /> },
      ],
    },
    {
      path: "/products",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/products/list" replace /> },
        { path: "list", element: <Products /> },
        { path: ":id", element: <AddProducts /> },
      ],
    },

    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Login /> },
        // { path: 'register', element: <Register /> },
        { path: "404", element: <NotFound /> },
        { path: "/dashboard", element: <DashboardApp /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
